import { useAuth, useUser } from "@clerk/nextjs";
import {
  PrimaryButton,
  SecondaryButton,
  Text,
} from "@cakemembers/components-core";

export function AlreadyLoggedIn() {
  const { signOut } = useAuth();
  const { user, isLoaded: isUserLoaded } = useUser();
  if (!isUserLoaded || !user) {
    return null;
  }
  return (
    <div className="flex flex-col gap-5">
      <Text className="text-center">
        You are currently signed in as {user.emailAddresses[0].emailAddress}
      </Text>
      <div className="flex flex-row justify-center gap-4">
        <PrimaryButton href="/home">Continue to Home</PrimaryButton>
        <div>
          <SecondaryButton
            onClick={() =>
              signOut(() => {
                console.log("signed out");
              })
            }
          >
            Sign Out
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
}
